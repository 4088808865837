<template>
  <div class="page" v-loading="pageLoading">
    <div class="playerMain">
      <div id="player"><p class="noVideo" v-show="!isVideo">暂无视频</p></div>
      <p class="title">{{ baseInfo.name }}</p>
      <div class="lessonList">
        <div class="listItem" :class="{active:currentIndex == index}" v-for="(item,index) in list" :key="index" @click="changeVideo(index)"><p>{{ item.name }}</p><img :src="currentIndex == index ? playing : playImg" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import playing from "@/assets/images/wjw/video_playing.png"
export default {
  name: "detail",
  components: {  },
  data() {
    return {
      isVideo:true,
      playing,
      playImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/play.jpg",
      pageLoading: false,
      id: "",
      type: "",
      player: "",
      name: "",
      from: "",
      url: "",
      goods_id: "",
      videoId: "",
      baseInfo: {},
      lessonId: "",
      list: [],
      lessonList: [],
      currentIndex: -1,
      currentInfo: {},

      videoType: '',
    };
  },
  watch: {
    menuIndex(e) {
      if (e != 1 && this.player) {
        this.player.pause();
      }
    },
  },
  mounted() {
    this.$router.onReady(async () => {
      this.lessonId = this.$route.query.lesson_id;
      this.goods_id = this.$route.query.id;
      this.getInit();
    });
    
  },
  methods: {
    changeVideo(index) {
        this.currentIndex = index
        this.currentInfo = this.list[this.currentIndex];
        this.getVideo();
    },

    async getInit() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/zhong/goods",
        data: {
          goods_id: this.goods_id
        },
      });
     
      this.pageLoading = false;
      if (res.code == 0) {
        // this.lessonList = res.data.list;
        this.list = this.flatArr(res.data.list, []);
        if(this.list.length){
          this.currentIndex = 0
          this.currentInfo = this.list[this.currentIndex]
          this.getVideo()
        }
        this.baseInfo = res.data.goods_info; //课程介绍
        console.log(this.list);
      } else {
        this.$toast(res.msg);
      }
    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "lesson" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },

    async getVideo() {
      if (this.player) {
        console.log('取消播放')
        this.videoType !='cc' ? this.player.dispose() : this.player.destroy();
      }
      let videoInfo = this.currentInfo.video_ids[0].resource[0];

      let id = videoInfo.ali_media_id;
      if(!id && !videoInfo.url && !videoInfo.ccid){
        this.isVideo = false
        return
      }
      this.isVideo = true
      if (id) {
        this.videoType = 'ali'
        let res = await this.$api({
          method: "get",
          url: "/api/video/play-auth/get",
          data: {
            video_id: id,
          },
        });
        if (res.code == 0) {
          this.player = new Aliplayer(
            {
              id: "player",
              width: "100%",
              height: "560px",
              vid: res.data.videoId,
              playauth: res.data.playAuth,
              skinLayout: [
                {
                  name: "bigPlayButton",
                  align: "blabs",
                  x: '50%',
                  y: '50%',
                },
                {
                  name: "H5Loading",
                  align: "cc",
                },
                {
                  name: "errorDisplay",
                  align: "tlabs",
                  x: 0,
                  y: 0,
                },
                {
                  name: "infoDisplay",
                },
                {
                  name: "tooltip",
                  align: "blabs",
                  x: 0,
                  y: 56,
                },
                {
                  name: "thumbnail",
                },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    {
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44,
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7,
                    },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    {
                      name: "subtitle",
                      align: "tr",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 5,
                      y: 10,
                    },
                  ],
                },
              ],
            },
            function (player) {
              // console.log("The player is created.");
            }
          );
        } else {
          this.$toast(res.msg);
        }
      } else if (videoInfo.url) {
        this.videoType = 'url'
        this.player = new Aliplayer(
          {
            id: "player",
            width: "100%",
            height: "560px",

            source: videoInfo.url,
            cover: "",
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "blabs",
                x: '50%',
                y: '50%',
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56,
              },
              {
                name: "thumbnail",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44,
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7,
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12,
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            // console.log("The player is created.");
          }
        );
      }else if(videoInfo.ccid){
        this.videoType = 'cc'
        
        this.player = window.createCCH5Player({
          vid: videoInfo.ccid,
          siteid: "23EEB4513490D389",
          // vid: 'AA22BDEE61E7455A9C33DC5901307461',
          // siteid: "2661F9756E5C832E",
          autoStart: true,
          playertype: 1,
          isShare: "false",
          banDrag: "false",
          width: "100%",
          height: "560px",
          loop: "true",
          showFullScreenBtn: "false",
          useX5Play: "true",
          isShowUrlBtn: "false",
          isShowMoreBtn: "false",
          objectFit: "cover", // contain 、 fill 、 cover
          parentNode: "#player",
          showFullScreenBtn: true
        });
        // this.player = window.createCCH5Player({
        //             vid: 'AA22BDEE61E7455A9C33DC5901307461',
        //             siteid: "2661F9756E5C832E",
        //             autoStart: true,
        //             width: "100%",
        //             height: "380px",
        //             parentNode: document.getElementById('player')
        //         });
        window.on_h5player_error = (err) => {
          console.log('失败')
          console.log(err)
        }
        window.on_CCH5player_ready = (obj)=>{
          console.log('ready')

        };
        window.on_CCH5player_play = (obj) =>{
          console.log('play')
          // this.videPlay()
        }
        window.on_CCH5player_pause = (obj) =>{
          console.log('videoPause')
          // this.videoPause()
        }
        
      }
      if(this.videoType !== 'cc'){
        this.player.on("play", () => {
          console.log('play2')
          // this.videPlay()
        });
        this.player.on("pause", () => {
          // this.videoPause();
        });
      }
      
      
    },
    
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    // currentInfo(){
    //   return this.$store.state.currentVideoInfo
    // }
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    // this.id = to.query.id;
    // this.getData();
    this.id = this.$route.query.id;
    this.goods_id = to.query.goods_id;
    this.videoId = to.query.videoId;
    this.type = to.query.type;
    this.name = to.query.name;
    this.from = to.query.from;
    this.url = to.query.url ? to.query.url : "";
    this.player.dispose();
    this.getInit();
    next();
  },
};
</script>
<style>
body {
  background: #fff;
}
</style>
<style lang="scss" scoped>
@import url("https://service.wormhoo.com/libs/js/vant2/index.css");
.gray {
  background: #d6d6d6 !important;
  color: #b3b3b3 !important;
}
.page {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  .title {
    font-size: 24px;
    color: #333333;
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #f0f0f0;
  }
  .playerMain {
    width: 1000px;
    margin: 40px auto 10px;
  }

  #player {
    width: 100%;
    height: 560px;
    padding-bottom: 0;
    background: #000;
  }
}
.lessonList{
  margin-top: 30px;
  border-radius: 4px;
  overflow: hidden;
  background: #f9f9f9;
  .listItem{
    height: 53px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
    &:not(:last-of-type){
      border-bottom: 1px solid #f0f0f0;
    }
    p{
      flex: 1;
      font-size: 16px;
      color: #333;
    }
  }
  .active{
    background: #dee7f8;
  }
}
.noVideo{
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.prism-player .prism-big-play-btn{
  transform: translate(-50%, 50%);
}
video {
  height: 560px;
}
</style>
